<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<nav class="bg-custom px-4 lg:px-6 py-2.5 w-full">
  <div class="content flex justify-between items-center w-full">
      <a routerLink="/" class="h-12" style="max-width: 284px; width: 284px">
        <img class="h-12" style="max-width: 284px; width: 284px"
             src="./assets/images/logos/logo_header.png"
             alt="Spare Cores Logo" />
      </a>
      <div class="justify-end items-center w-full flex w-auto order-1" id="mobile-menu-2">
          <ul class="flex font-medium flex-row space-x-8 mt-0 items-center">
              <li class="hidden lg:flex">
                  <a class="navbar_link" routerLink="/servers" >
                    <lucide-icon class="h-4 w-4" name="pc-case"></lucide-icon>
                    Servers
                  </a>
              </li>
              <li class="hidden lg:flex">
                <a class="navbar_link" routerLink="/compare" >
                  <lucide-icon class="h-4 w-4" name="scale"></lucide-icon>
                  Compare Servers
                </a>
              </li>
              <li class="hidden lg:flex" >
                <div class="navbar_link cursor-pointer" id="prices_button">
                  <lucide-icon
                    class="cursor-pointer h-4 w-4"
                    name="dollar-sign" >
                  </lucide-icon>
                  Prices
                </div>
              </li>
              <li class="hidden lg:flex">
                <a class="navbar_link" routerLink="/regions" >
                  <lucide-icon class="h-4 w-4" name="hotel"></lucide-icon>
                  Regions
                </a>
              </li>
              <li class="hidden lg:flex">
                <a class="navbar_link" routerLink="/vendors" >
                  <lucide-icon class="h-4 w-4" name="home"></lucide-icon>
                  Vendors
                </a>
              </li>
              <li [ngClass]="
                    {'hidden' : compareCount() === 0}"
                >
                <button
                  id="compare_button"
                  class="text-white bg-emerald-400 rounded-lg px-3 py-3 relative" >
                  <span
                    class="h-4 w-4 absolute text-white bg-red-500 z-10 top-2 right-2 rounded-full"
                    style="top: 2px; font-size: 0.625rem; right: 2px" >{{compareCount()}}
                  </span>
                  <lucide-icon class="h-4 w-4" name="scale"></lucide-icon>
                </button>
              </li>
              <li class="lg:hidden">
                <lucide-icon
                  id="menu_button"
                  class="navbar_link cursor-pointer h-8 w-8"
                  name="menu" >
                </lucide-icon>
              </li>
          </ul>
      </div>
  </div>
</nav>


<div id="menu_options" class="z-10 hidden bg-secondary rounded-lg shadow w-52 border border-emerald-400 border-solid">
  <ul
    class="py-2 px-1 text-sm text-gray-700 dark:text-gray-200"
    aria-labelledby="dropdownDefaultButton"
    (click)="closeMenu()">
    <li class="py-2">
      <a class="navbar_link" routerLink="/servers" >
        <lucide-icon class="h-4 w-4" name="pc-case"></lucide-icon>
        Servers
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/compare" >
        <lucide-icon class="h-4 w-4" name="scale"></lucide-icon>
        Compare Servers
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/server_prices" >
        <lucide-icon class="h-4 w-4" name="dollar-sign"></lucide-icon>
        Server Prices
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/storages" >
        <lucide-icon class="h-4 w-4" name="database"></lucide-icon>
        Storage Prices
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/traffic-prices" >
        <lucide-icon class="h-4 w-4" name="arrow-up-down"></lucide-icon>
        Traffic Prices
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/regions" >
        <lucide-icon class="h-4 w-4" name="hotel"></lucide-icon>
        Regions
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/vendors" >
        <lucide-icon class="h-4 w-4" name="home"></lucide-icon>
        Vendors
      </a>
    </li>
  </ul>
</div>

<div id="prices_options" class="z-100 hidden bg-secondary rounded-lg shadow w-52 border border-emerald-400 border-solid">
  <ul
    class="py-2 px-1 text-sm text-gray-700 dark:text-gray-200"
    aria-labelledby="dropdownDefaultButton"
    (click)="closePrices()">
    <li class="py-2">
      <a class="navbar_link" routerLink="/server_prices" >
        <lucide-icon class="h-4 w-4" name="pc-case"></lucide-icon>
        Server Prices
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/storages" >
        <lucide-icon class="h-4 w-4" name="database"></lucide-icon>
        Storage Prices
      </a>
    </li>
    <li class="py-2">
      <a class="navbar_link" routerLink="/traffic-prices" >
        <lucide-icon class="h-4 w-4" name="arrow-up-down"></lucide-icon>
        Traffic Prices
      </a>
    </li>
  </ul>
</div>



<div id="compare_options" class="z-10 hidden bg-secondary rounded-lg shadow w-250 border border-emerald-400 border-solid">
  <ul
    class="py-2 px-1 text-sm text-gray-700 dark:text-gray-200"
    aria-labelledby="dropdownDefaultButton"
    (click)="closeCompare()">
    <li class="py-2 flex justify-between" *ngFor="let item of getServersForCompare()">
      <a class="navbar_link" routerLink="/server/{{item.vendor}}/{{item.server}}" >
        <lucide-icon class="h-4 w-4" name="pc-case"></lucide-icon>
        {{item.vendor}}: {{item.display_name}}
      </a>
      <div (click)="$event.stopPropagation()">
        <button
          class="text-red-500 px-2 py-2"
          (click)="removeFromCompare($event, item)">
          <lucide-icon class="h-4 w-4" name="trash"></lucide-icon>
        </button>
      </div>
    </li>
    <li class="py-2">
      <button class="w-full font-bold text-white bg-emerald-400 hover:bg-emerald-500 rounded-sm py-2"
        (click)="compareServers()">
        Compare
      </button>
    </li>
  </ul>
</div>
